import Link from "next/link";
import Styles from "@/styles/Footer.module.css";
import dynamic from "next/dynamic";
import { useState } from "react";
import axios from "axios";
const AllImageSettings = dynamic(() => import("@/components/AllImageSettings"));

const FooterApps = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  const onSubmitNewsleatter = async (e) => {
    setMessage("");
    setMessageError("");
    e.preventDefault();
    let fd = new FormData();
    fd.append("email", email);
    await axios
      .post(
        process.env.REACT_APP_DATA_URL + "/frontend-bn/newsletter/submit",
        fd,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setMessage(res.data);
      })
      .catch((error) => {
        if (error.response) {
          setMessageError(
            error.response.data.errors ? error.response.data.errors.email : ""
          );
        }
      });
  };
  return (
    <div className="col-lg-3 text-sm-center">
      {/* <div
        className={`${Styles.widget} ${Styles.widget_tag_cloud} row pl-3  mb-2`}
        style={{
          width: "100%",
          marginBottom: "15px",
        }}
      >
        <h2 className={`${Styles.widget__title}`}>
          TBN24 মোবাইল অ্যাপস ডাউনলোড করুন
        </h2>
        <div className="col-md-6 col-sm-6 col-6">
          <Link
            href={
              "https://play.google.com/store/apps/details?id=com.tbn.live&pli=1"
            }
            target={"_blank"}
            title=" TBN24 মোবাইল অ্যাপস ডাউনলোড করুন"
          >
            <AllImageSettings
              type={"footerApps"}
              image={"/frontend-bn/img/playStore.png"}
              alt="TBN24 playStore Logo"
            />
          </Link>
        </div>
        <div className="col-md-6 col-sm-6 col-6">
          <Link
            href={"https://apps.apple.com/us/app/tbn24/id963111684"}
            target={"_blank"}
            title=" TBN24 মোবাইল অ্যাপস ডাউনলোড করুন"
          >
            <AllImageSettings
              type={"footerApps"}
              image={"/frontend-bn/img/appleStore.png"}
              alt="TBN24 appleStore Logo"
            />
          </Link>
        </div>
      </div> */}
      <div
        className={`${Styles.widget} ${Styles.widget_tag_cloud} row pl-3 mb-2`}
        style={{ width: "100%" }}
      >
        <h4 className={`${Styles.widget__title} `}>
          Palki TV মোবাইল অ্যাপস ডাউনলোড করুন
        </h4>
        <div className="col-md-6 col-sm-6 col-6">
          <Link
            href={
              "https://play.google.com/store/apps/details?id=pl.newiq.palkitv&pli=1"
            }
            target={"_blank"}
            title="  Palki TV মোবাইল অ্যাপস ডাউনলোড করুন"
          >
            <AllImageSettings
              type={"footerApps"}
              image={
                process.env.REACT_APP_BASE_URL +
                "/frontend-bn/img/playStore.png"
              }
              alt="TBN24 playStore Logo"
            />
          </Link>
        </div>

        <div className="col-md-6 col-sm-6 col-6">
          <Link
            href={"https://apps.apple.com/us/app/palki-tv/id1629312638"}
            target={"_blank"}
            title="  Palki TV মোবাইল অ্যাপস ডাউনলোড করুন"
          >
            <AllImageSettings
              type={"footerApps"}
              image={
                process.env.REACT_APP_BASE_URL +
                "/frontend-bn/img/appleStore.png"
              }
              alt="TBN24 appleStore Logo"
            />
          </Link>
        </div>
        <h4 className={`${Styles.widget__title} mt-5 text-center`}>
          সব ধরনের নিউজ আপডেট পেতে <br /> সাবস্ক্রাইব করুন
        </h4>

        <form onSubmit={onSubmitNewsleatter} className={Styles.newsletter}>
          <input
            type="text"
            className={Styles.newsletter_input}
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            type="submit"
            aria-label="submit"
            title="Submit Now"
            className={Styles.btnstyle}
          >
            Submit
          </button>
          {message ? (
            <p style={{ color: "#fff", width: "100%", textAlign: "center" }}>
              {message}
            </p>
          ) : (
            ""
          )}
          {messageError ? (
            <p
              style={{
                color: "red !important",
                width: "100%",
                textAlign: "center",
              }}
            >
              {messageError}
            </p>
          ) : (
            ""
          )}
        </form>
      </div>
    </div>
  );
};

export default FooterApps;
